<template>
  <newspapers></newspapers>
</template>

<script>
import Newspapers from '@/components/newspaper/Newspapers.vue'

export default {
  name: 'NewspapersView',
  components: {
    Newspapers
  }
}
</script>
